import { testi2 } from "./types";

const initialState = {
    testi: false,
};

const view2 = (state = initialState, action) => {
    switch (action.type) {
        case testi2: {
            return {
                ...state,
                testi: action.payload.testi,
            };
        }
        default: {
            return state;
        }
    }
};

export default view2;
