import { testi1 } from "./types";

const initialState = {
  testi: false,
};

const view = (state = initialState, action) => {
  switch (action.type) {
    case testi1: {
      return {
        ...state,
        testi: action.payload.testi,
      };
    }
    default: {
      return state;
    }
  }
};

export default view;
