import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "ramda";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

const styles = {
  container: {
    minWidth: "100%",
    minHeight: "100%",
  },
  input: {
    fontSize: "26px",
    background: "black",
    color: "white",
    borderColor: "white",
  },
};

const SignInPopup = ({ classes, handleClose, dialogState }) => {
  const { handleSubmit, register /* errors */ } = useForm();

  const onSubmit = (data, e) => {
    console.log("data: ", data);
  };

  return (
    <Dialog open={dialogState} onClose={handleClose}>
      <div>
        <AppBar style={{ background: "transparent" }}>
          <Toolbar>
            <IconButton
              style={{ color: "white" }}
              edge={"start"}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: "500px",
            width: "400px",
            border: "1px solid white",
            background:
              "linear-gradient(128deg, rgba(0,0,0,1) 10%, rgba(34,34,34,1) 50%, rgba(68,68,68,1) 90%)",
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ paddingTop: "5.5rem", color: "white" }}>
                Email:
              </label>
              <input
                name={"email"}
                placeholder={"Bad@Example.com"}
                className={classes.input}
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              <label style={{ marginTop: "5.5rem", color: "white" }}>
                Password:
              </label>
              <input
                name={"password"}
                placeholder={"*"}
                className={classes.input}
                type={"password"}
                ref={register({
                  required: "Required",
                })}
              />
              <Button
                variant={"outlined"}
                style={{
                  marginTop: "4rem",
                  maxWidth: "100px",
                  margin: "auto",
                  color: "white",
                  border: "1px solid white",
                }}
                type={"submit"}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SignInPopup);
