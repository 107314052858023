import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "ramda";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// import * as viewActions from "../redux/view/actions";

const styles = {
  container: {
    minHeight: "100%",
    maxHeight: "100%",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "white",
    background:
      "linear-gradient(128deg, rgba(0,0,0,1) 10%, rgba(34,34,34,1) 50%, rgba(68,68,68,1) 90%)",
  },
  list: {
    minWidth: "360px",
    display: "flex",
    justifyContent: "center",
    flex: 3,
    overflowY: "scroll",
    maxHeight: "70vh",
    marginBottom: "1rem",
  },
  header: {
    minWidth: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "-webkit-center",
  },
};

const Projects = ({ classes }) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <p style={{ textTransform: "upperCase", fontWeight: "lighter" }}>
            A little bit about my projects
          </p>
          <p style={{ fontSize: "xx-large" }}>
            I complete these on my spare time, using React
          </p>
          <p style={{ fontWeight: "600" }}>Junior Full-Stack Developer</p>
        </div>
      </div>
      <div className={classes.list}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button alignItems={"center"}>
            <ListItemText primary="Inbox" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Drafts" />
          </ListItem>
          <ListItem button alignItems={"center"}>
            <ListItemText primary="Inbox" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Drafts" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Projects);
