import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "ramda";
import { connect } from "react-redux";
import code from "../images/code.jpg";
import collections from "../images/collections.jpg";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import SignInPopup from "./SignInPopup";

// import * as viewActions from "../redux/view/actions";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  leftContainer: {
    display: "flex",
    transition: "flex 2s, filter 2s",
    backgroundSize: "cover",
  },
  rightContainer: {
    display: "flex",
    transition: "flex 2s, filter 2s",
    backgroundSize: "cover",
  },
  buttonContainers: {
    margin: "auto",
    border: "2px solid white",
    background: "rgba(107, 107, 107,0.7)",
  },
};

const HomePage = ({ classes }) => {
  const [rightFlex, setRightFlex] = useState(1);
  const [leftFlex, setLeftFlex] = useState(1);
  const [leftBrightness, setLeftBrightness] = useState(0.9);
  const [rightBrightness, setRightBrightness] = useState(0.9);
  const [dialogState, setDialogState] = useState(false);

  const history = useHistory();

  const handleMouseOver = (side) => {
    if (side === "right") {
      setRightFlex(1);
      setLeftFlex(2);
      setLeftBrightness(0.2);
      setRightBrightness(0.9);
    } else {
      setLeftFlex(1);
      setRightFlex(2);
      setLeftBrightness(0.9);
      setRightBrightness(0.2);
    }
  };
  return (
    <div className={classes.container}>
      <div
        onClick={() => {
          handleMouseOver("left");
        }}
        onMouseOver={() => {
          handleMouseOver("left");
        }}
        className={classes.leftContainer}
        style={{
          flex: rightFlex,
          backgroundImage: `url(${code})`,
          filter: `brightness(${leftBrightness})`,
        }}
      >
        <div className={classes.buttonContainers}>
          <Button
            onClick={() => history.push("/projects")}
            style={{ color: "white", fontSize: "1.5rem", minWidth: "200px" }}
            variant={"outlined"}
          >
            Projects
          </Button>
        </div>
      </div>
      <div
        className={classes.rightContainer}
        style={{
          flex: leftFlex,
          backgroundImage: `url(${collections})`,
          filter: `brightness(${rightBrightness})`,
        }}
        onClick={() => {
          handleMouseOver("right");
        }}
        onMouseOver={() => {
          handleMouseOver("right");
        }}
      >
        <div className={classes.buttonContainers}>
          <Button
            onClick={() => {
              setDialogState(true);
            }}
            style={{ color: "white", fontSize: "1.5rem", minWidth: "200px" }}
            variant={"outlined"}
          >
            Private
          </Button>
        </div>
      </div>
      <SignInPopup
        handleClose={() => {
          setDialogState(false);
        }}
        dialogState={dialogState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(HomePage);
