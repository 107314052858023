import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "ramda";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as viewActions from "./redux/view/actions";
import HomePage from "./homePage/HomePage";
import Projects from "./projects/Projects";

const styles = {
  fullViewPortContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
};

function App(props) {
  const { classes } = props;
  return (
    <Router>
      <div className={classes.fullViewPortContainer}>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  testi1: state,
});

const mapDispatchToProps = (dispatch) => ({
  testiAction: () => {
    dispatch(viewActions.testi1());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(App);
